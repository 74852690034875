import skryv from "@skryv/core-ng1";
import { navigation, theme, translation, form, search } from "@skryv/core-ng1";

import { selectTasklistCount } from "@skryv/core-ng1/core/store/selectors/tasks";

import wrpCustomizations from "./customizations";
import logo from "./theme/assets/images/logo.png";
import nl_BE from "./translations/nl_BE.po";

import { unassignedTasksDefinitionKeys } from "./customizations/components/dashboard/UnassignedTasksDashboard/UnassignedTasksDashboard";
import { appealDefinitionKeys } from "./customizations/components/dashboard/UnassignedAppealsDashboard/UnassignedAppealsDashboard";

const skrPages = skryv.constants.navigation.pageNames;
const skrSearchTables = skryv.constants.search.searchNames;

theme.logo(logo, "Mijn Verbouwpremie");

translation.add("nl_BE", nl_BE).locale("nl_BE");

window.useESWithFGAs = true;

form
  .addCustomComponentName(
    "wrpFactuurcontroleWizard",
    "wrpFactuurcontroleWizard"
  )
  .addCustomComponentName("wrpCategorySelector", "wrpCategorySelector")
  .addCustomComponentName(
    "wrpFactuurcontroleOverview",
    "wrpFactuurcontroleOverview"
  );

navigation
  .page(skrPages.DASHBOARD, {
    extend: true,
    views: {
      actions: "wrpDashboardActions",
      content: "wrpMyTasksDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page(skrPages.DASHBOARD_DOSSIERS, {
    extend: true,
    views: {
      actions: "wrpDashboardActions",
      content: "wrpMyDossiersDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page("dashboardUnassignedTasks", {
    extend: skrPages.DASHBOARD,
    url: "/dashboard/unassigned-tasks",
    views: {
      content: "wrpUnassignedTasksDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page("dashboardAppeal", {
    extend: skrPages.DASHBOARD,
    url: "/dashboard/appeal",
    views: {
      content: "wrpUnassignedAppealsDashboard",
    },
    resolve: { initSubmenuBadges: initDashboardSubmenuBadges() },
  })
  .page(skrPages.TASK, {
    extend: true,
    views: {
      summary: "wrpTaskSummary",
    },
  })
  .page(skrPages.SUBTASK, {
    extend: true,
    views: {
      summary: "wrpTaskSummary",
    },
  })
  .page(skrPages.DOSSIER, {
    extend: true,
    views: {
      content: "wrpDossierPage",
    },
  })
  .page(skrPages.SEARCH_DOSSIERS, {
    extend: true,
    views: {
      content: "wrpDossierSearch",
    },
  })
  .page("searchTasksCustom", {
    extend: skrPages.SEARCH_TASKS,
    views: {
      content: "wrpTasksSearch",
    },
  })
  .page(skrPages.SEARCH_HISTORIC_TASKS, {
    extend: true,
    views: {
      content: "wrpHistoricTasksSearch",
    },
  })
  .tab("dashboard", "dashboardUnassignedTasks", {
    label: "Te verdelen taken",
    state: "dashboardUnassignedTasks",
    stateOpts: { reload: true },
    badgeQuery: (state) => {
      return selectTasklistCount(state, "dashboardUnassignedTasks");
    },
  })
  .tab("dashboard", "dashboardAppeal", {
    label: "Te verdelen beroepen",
    state: "dashboardAppeal",
    stateOpts: { reload: true },
    badgeQuery: (state) => {
      return selectTasklistCount(state, "dashboardAppeal");
    },
  })
  .tab("dashboard", "my_tasks", {
    label: "Mijn open taken",
    extend: true,
  })
  .tab("dashboard", "dossiers", {
    label: "Mijn dossiers",
    extend: true,
    badgeQuery: () => false, // turn this off since the number will only go up
  })
  .tab("search", "tasksCustom", {
    label: "Open taken",
    state: "searchTasksCustom",
    stateOpts: { reload: true },
  })
  .tab("search", "tasks", {
    label: "Open taken (filterbaar)",
    extend: true,
    authorizationKey: "SEARCH_OPEN_TASKS",
  })
  .setTabsForGroup("search", [
    "dossiers",
    "tasksCustom",
    "tasks",
    "historicTasks",
    "documents",
  ]);

search
  .setAdvancedSearchConfigurationFor(skrSearchTables.SEARCH_DOSSIERS, {
    definition: {
      computedExpressions: {
        valid_ssin:
          "($.rijksregisternummer && $.rijksregisternummer.length > 0) ? (97 - ($.rijksregisternummer.substring(0,9) % 97) - $.rijksregisternummer.substring(9,11) === 0) || (97 - ((2 + $.rijksregisternummer.substring(0,9)) % 97) - $.rijksregisternummer.substring(9,11) === 0) : true",
      },
      fields: [
        {
          type: "text",
          label: "Rijksregisternummer",
          name: "rijksregisternummer",
          maskConfig: {
            predefinedMask: "ssin",
          },
          conditions: [
            {
              name: "rijksregisternummer",
              level: "error",
              expression: "$$.computedExpressions.valid_ssin",
              errorMessage:
                "Gelieve een geldig rijksregisternummer in te geven.",
            },
          ],
        },
        {
          type: "fieldset",
          label: "Adres",
          name: "address",
          customComponentName: "skrAddressInput",
          fields: [
            {
              type: "text",
              label: "Straatnaam",
              name: "street",
            },
            {
              type: "text",
              label: "Huisnummer",
              name: "housenumber",
            },
            {
              type: "text",
              label: "Busnummer",
              name: "boxnumber",
            },
            {
              type: "number",
              label: "Postcode",
              name: "zipcode",
            },
            {
              type: "text",
              label: "NIS code",
              name: "nis_code",
            },
            {
              type: "text",
              label: "Gemeente",
              name: "municipality",
            },
          ],
        },
        {
          name: "eanCodeWoning",
          label: "EAN-code uitvoeringsadres",
          type: "text",
          maskConfig: {
            predefinedMask: "ean",
          },
          conditions: [
            {
              name: "geldigeEANcodeWoning",
              level: "error",
              expression:
                "($ === '' || $ === undefined) ? true : ((10 - (((5*3 + 4 + 1*3 + 4 + 4*3 + parseInt($[0]) + parseInt($[1])*3 + parseInt($[2]) + parseInt($[3])*3 + parseInt($[4]) + parseInt($[5])*3 + parseInt($[6]) + parseInt($[7])*3 + parseInt($[8]) + parseInt($[9])*3 + parseInt($[10]) + parseInt($[11])*3)) % 10)) % 10) === parseInt($[12])",
              errorMessage: "Gelieve een geldig EAN-nummer in te geven",
            },
          ],
        },
        {
          name: "naam",
          label: "Naam",
          type: "text",
        },
        {
          name: "voornaam",
          label: "Voornaam",
          type: "text",
        },
      ],
    },
    queryRules: {
      rijksregisternummer: {
        paths: ["document.aanvraag.rijksregisternummerAanvrager"],
      },
      "address.street": {
        paths: ["document.aanvraag.uitvoeringsadres.street"],
      },
      "address.housenumber": {
        paths: ["document.aanvraag.uitvoeringsadres.housenumber"],
      },
      "address.boxnumber": {
        paths: ["document.aanvraag.uitvoeringsadres.boxnumber"],
      },
      "address.municipality": {
        paths: ["document.aanvraag.uitvoeringsadres.municipality"],
      },
      "address.zipcode": {
        paths: ["document.aanvraag.uitvoeringsadres.zipcode"],
      },
      eanCodeWoning: {
        paths: ["document.aanvraag.eanAndAddress.eanCodeWoning"],
      },
      naam: {
        paths: ["document.aanvraag.naam"],
      },
      voornaam: {
        paths: ["document.aanvraag.voornaam"],
      },
    },
  })
  .defaultForTasks({
    name: "Default view",
    searchTerm: "_exists_:task AND dossier.label:2?-MVP-*",
    columns: [
      "task.name",
      "dossier.label",
      "task.due",
      "task.assigneeUser.username",
      "task.created",
    ],
    filters: [],
    sort: {
      criterion: "task.created",
      desc: true,
    },
  });

skryv
  .customize(wrpCustomizations)
  .customize(navigation)
  .customize(theme)
  .customize(translation)
  .customize(form)
  .customize(search)
  .initialize(document.getElementById("root"));

function initDashboardSubmenuBadges() {
  return ($ngRedux, userService) => {
    "ngInject";
    // this function is called upon initialisation of the submenu;
    // it makes sure that all required data is fetched so the numbers for the submenu badges can be selected from the state
    return () => {
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "tasks_mine",
        api: {
          params: {
            assignee: userService.getCurrentUserSub(),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "dashboardUnassignedTasks",
        api: {
          params: {
            isAssigned: false,
            taskDefinitionKey: unassignedTasksDefinitionKeys.join(","),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
      $ngRedux.dispatch({
        type: "FETCH_TASK_FILTER_COUNT",
        listKey: "dashboardAppeal",
        api: {
          params: {
            isAssigned: false,
            taskDefinitionKey: appealDefinitionKeys.join(","),
          },
          url: "/api/tasks/filtered/count",
          method: "GET",
        },
      });
    };
  };
}
