import angular from "angular";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import { fetchBulkActions } from "@skryv/core-ng1/core/store/actions/tasks";
import { selectBulkActions } from "@skryv/core-ng1/core/store/selectors/bulkActions";

import template from "./DashboardActions.html";

const namespace = "wrp/customizations/components/dashboard/DashboardActions";

// const fetchExtendedTasks = () => {
//   return {
//     type: "FETCH_EXTENDED_TASKS",
//     api: {
//       url: "api/extendedtasks/filtered?page=0&size=20&assignee=6c86a67f-27bb-4ec4-9a1f-f9cb6f9c538f&sort=due,asc",
//       method: "GET",
//     },
//   };
// };

angular.module(namespace, []).component("wrpDashboardActions", {
  template,
  bindings: {
    isAuthorizedForNewDossier: "<",
  },
  controller: function ($ngRedux, dialogService) {
    "ngInject";

    const $ctrl = this;
    const disconnect = $ngRedux.connect(mapStateToThis, {
      fetchBulkActions,
    })(this);
    this.$onDestroy = disconnect;

    this.$onInit = () => {
      this.actions = this.buildActionsMenu();
      this.fetchBulkActions();

      this.initialized = true;
    };

    this.buildActionsMenu = (bulkTasks = []) => {
      let actions = [];

      if (this.isAuthorizedForNewDossier) {
        actions.push({
          label: "New dossier",
          key: "start",
          order: 1,
          action: function () {
            dialogService.openDialog(
              "New dossier",
              "<wrp-dossier-new></wrp-dossier-new>"
            );
          },
        });
      }

      if (isEmpty(bulkTasks)) return actions;

      let subActions = map(bulkTasks, (task) => {
        return {
          key: task.taskDefinitionKey,
          label: task.label,
          icon: "skr-icon-task",
          action: () => {
            const data = { task };
            dialogService.openDialog(
              "New bulk dossier",
              "<skr-dossier-bulk-dialog></skr-dossier-bulk-dialog>",
              data
            );
          },
        };
      });

      actions.push({
        label: "Bulk actions",
        isDropdown: true,
        order: 2,
        subActions,
      });
      return actions;
    };

    function mapStateToThis(state) {
      // it's possible that the component hasn't initialized fully at this point.
      // check if the component has initialized fully and if not, just return...
      if (!$ctrl.initialized) return {};
      const bulkTasks = selectBulkActions(state);
      if (bulkTasks && !isEmpty(bulkTasks)) {
        return {
          actions: $ctrl.buildActionsMenu(bulkTasks),
        };
      }
      return {};
    }
  },
});

export { template };
export default namespace;
