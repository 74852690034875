export default {
  // milestones : refer to name field in BPMN milestones
  // to be used with getCurrentMilestoneLabel()
  REQUEST_INITIATED: "aanvraagOpgestart",
  FETCH_DATA_CITIZEN: "dataOphalenParticulieren",
  FETCH_DATA_COMPANY: "dataOphalenBedrijven",
  FILLING_IN_REQUEST: "aanvraagInvullen",
  REQUEST_SUBMITTED: "aanvraagIngediend",
  CONTACT_FORM_SUBMITTED: "contactformulierIngevuld",
  CONTACT_DETAILS_CHANGED: "contactgegevensAangepast",
  CALCULATION_ADVICE_STARTED: "berekeningAdviesOpgestart",
  CALCULATION_ADVICE_COMPLETED: "berekeningAdviesVoltooid",
  AUTHENTIC_DATA_STARTED: "authentiekeDataOpgestart",
  AUTHENTIC_DATA_CANCELLED: "authentiekeDataOphalenGeannuleerd",
  REQUEST_CHANGED_AUTHENTIC_DATA: "aanvraagAangepastAuthentiekeData",
  MANUAL_ADRESS_COMPARISON_STARTED: "manueleAdresvergelijkingOpgestart",
  AUTHENTIC_DATA_FETCHED: "authentiekeDataOpgehaald",
  ADDITIONAL_ATTACHMENTS_DELIVERED: "bijkomendeStukkenAangeleverd",
  TIMER_ADDITIONAL_ATTACHMENTS: "timerBijkomendeStukken",
  ADDITIONAL_ATTACHMENTS_CANCELED: "ophalenBijkomendeStukkenGeannuleerd",
  ATTEST_CONTROL_STARTED: "attestControleOpgestart",
  ATTEST_CONTROL_SKIPPED: "attestControleNietNodig",
  ADVICE_CONTROL_STARTED: "controleAdviesOpgestart",
  ADVICE_CONTROL_SKIPPED: "controleAdviesNietNodig",
  BULK_ADMIN_CONTROL_STARTED: "bulkValidatieAdministratieveControleOpgestart",
  QUALITY_ADMIN_CONTROL_SKIPPED: "administratieveKwaliteitscontroleNietNodig",
  QUALITY_ADMIN_CONTROL_STARTED: "administratieveKwaliteitscontroleOpgestart",
  TECHNICAL_CONTROL_STARTED: "technischeControleOpgestart",
  TECHNICAL_CONTROL_SKIPPED: "technischeControleNietNodig",
  FINANCIAL_CONTROL_STARTED: "financieleControleOpgestart",
  DOSSIER_TEMP_REJECTED: "dossierVoorlopigGeweigerd",
  DOSSIER_TEMP_APPROVED: "dossierVoorlopigGoedgekeurd",
  DOSSIER_DEF_REJECTED: "dossierDefinitiefGeweigerd",
  DOSSIER_DEF_APPROVED: "dossierDefinitiefGoedgekeurd",
  BULK_DECISION_CONTROL_STARTED: "bulkValidatieBeslissingOpgestart",
  QUALITY_CONTROL_DECISION_SKIPPED: "kwaliteitscontroleBeslissingNietNodig",
  QUALITY_CONTROL_DECISION_STARTED: "kwaliteitscontroleBeslissingOpgestart",
  BULK_SENDING_DECISION_LETTER: "bulkBeslissingsbriefInWacht",
  DECISION_LETTER_SENT: "beslissingsbriefVerstuurd",
  ADDITIONAL_ATTACHMENTS_REQUESTED: "bijkomendeStukkenOpgevraagd",
  MANUAL_REJECTION_STARTED: "manueleWeigeringOpgestart",
  REQUEST_ADAPTED: "aanvraagAangepast",
  REQUEST_DATA_ADAPTED: "aanvraagdatumAangepast",
  AUTHENTIC_DATA_ADAPTED: "authentiekeDataManueelAangepast",
  ADVICE_ADAPTED: "adviesManueelAangepast",
  AUTHENTIC_DATA_RELOADED: "authentiekeDataManueelHerladen",
  CHANGE_OF_REQUESTOR_REQUESTED: "wijzigingAanvragerAangevraagd",
  GO_BACK_TO_ADVICE: "manueelTerugNaarAdvies",
  GO_BACK_TO_DECISION: "manueelTerugNaarBeslissing",
};
