import { get, isFunction, isUndefined, map, memoize } from "lodash";

import iconsForCategory from "@skryv/bundle/customizations/constants/iconsForCategories";
import categorieLabels from "@skryv/bundle/customizations/constants/categorieLabels";

export function getComputedExpressionValue(
  documentModel,
  computedExpressionsName,
  ...args
) {
  const expression = get(documentModel, [
    "manipulator",
    "computedExpressions",
    computedExpressionsName,
  ]);
  return isFunction(expression) ? expression(...args) : expression;
}

// This function can be memoized; if you pass it a certain number, you should always get the same result
export const formatAmount = memoize((number) => {
  if (isUndefined(number)) return 0;

  // rounding
  let roundedNumber = Math.round(number * 100) / 100;

  let [integer, fraction] = roundedNumber.toString().split(".");

  // add points as thousand separator
  integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

  // if no fraction, keep it that way
  if (!fraction) {
    return `€\u00A0${integer}`; // u00A0 = non-breaking space
  }

  // if one decimal number, make it two
  if (fraction && fraction.length === 1) fraction = fraction * 10;

  // replace . with , as decimal separator
  return `€\u00A0${[integer, fraction].join(",")}`; // u00A0 = non-breaking space
});

export function extendedCircleInfoForTechnischeControle(
  requestedCategoriesKeys,
  allApprovedCategoriesKeys,
  allRejectedCategoriesKeys
) {
  return [
    Object.assign({}, baseCircleInfoForTotal(), {
      text: "Klaar",
      approvedAmount: () => 0,
      rejectedAmount: () => 0,
      maximum: () => 0,
    }),
    Object.assign({}, baseCircleInfoForPremium(), {
      text: "Premie",
      approvedAmount: () => 0,
      rejectedAmount: () => 0,
      maximum: () => 0,
      minimum: 0,
    }),
    ...map(requestedCategoriesKeys, (categoryKey) =>
      Object.assign(
        {},
        baseCircleInfoForCategory(
          allApprovedCategoriesKeys,
          allRejectedCategoriesKeys,
          categoryKey,
          statusForTechnischeControle(allRejectedCategoriesKeys, categoryKey),
          progressForTechnischeControle(allRejectedCategoriesKeys, categoryKey)
        ),
        {
          approvedAmount: () => 0,
          rejectedAmount: () => 0,
          maximum: () => 0,
          premiumAmount: () => 0,
        }
      )
    ),
  ];
}

export function compactCircleInfoForTechnischeControle(
  requestedCategoriesKeys,
  allApprovedCategoriesKeys,
  allRejectedCategoriesKeys
) {
  return [
    baseCircleInfoForTotal(),
    baseCircleInfoForPremium(),
    ...map(requestedCategoriesKeys, (categoryKey) =>
      baseCircleInfoForCategory(
        allApprovedCategoriesKeys,
        allRejectedCategoriesKeys,
        categoryKey,
        statusForTechnischeControle(allRejectedCategoriesKeys, categoryKey),
        progressForTechnischeControle(allRejectedCategoriesKeys, categoryKey)
      )
    ),
  ];
}

export function extendedCircleInfoForFactuurControle(
  requestedCategoriesKeys,
  allApprovedCategoriesKeys,
  allRejectedCategoriesKeys,
  totalAmountValidated,
  totalAmountApproved,
  totalAmountRejected,
  totalAmountMax,
  totalPremiumValidated,
  totalPremiumMax,
  categoryAmountApproved,
  categoryAmountRejected,
  categoryAmountMax,
  categoryAmountMin,
  categoryPremium,
  categoryCalculation
) {
  return [
    Object.assign(
      {},
      baseCircleInfoForTotal(
        () =>
          statusForFactuurcontrole(totalAmountValidated(), 0, totalAmountMax()),
        () =>
          progressForFactuurcontrole(totalAmountValidated(), totalAmountMax())
      ),
      {
        text: "Klaar",
        approvedAmount: () => totalAmountApproved(),
        rejectedAmount: () => totalAmountRejected(),
        maximum: () => totalAmountMax(),
      }
    ),
    Object.assign(
      {},
      baseCircleInfoForPremium(
        () =>
          statusForFactuurcontrole(
            totalPremiumValidated(),
            0,
            totalPremiumMax()
          ),
        () =>
          progressForFactuurcontrole(totalPremiumValidated(), totalPremiumMax())
      ),
      {
        text: "Premie",
        approvedAmount: () => totalPremiumValidated(),
        rejectedAmount: () => 0,
        maximum: () => totalPremiumMax(),
        minimum: 0,
      }
    ),
    ...map(requestedCategoriesKeys, (categoryKey) =>
      Object.assign(
        {},
        baseCircleInfoForCategory(
          allApprovedCategoriesKeys,
          allRejectedCategoriesKeys,
          categoryKey,
          () =>
            statusForFactuurcontrole(
              categoryAmountApproved(categoryKey),
              categoryAmountMin(categoryKey),
              categoryAmountMax(categoryKey),
              !allApprovedCategoriesKeys[categoryKey]
            ),
          () =>
            progressForFactuurcontrole(
              categoryAmountApproved(categoryKey),
              categoryAmountMax(categoryKey),
              !allApprovedCategoriesKeys[categoryKey]
            )
        ),
        {
          approvedAmount: () => categoryAmountApproved(categoryKey),
          rejectedAmount: () => categoryAmountRejected(categoryKey),
          minAmount: () => categoryAmountMin(categoryKey),
          maxAmount: () => categoryAmountMax(categoryKey),
          premiumAmount: () => categoryPremium(categoryKey),
          calculation: categoryCalculation(categoryKey),
        }
      )
    ),
  ];
}

export function compactCircleInfoForFactuurControle(
  requestedCategoriesKeys,
  allApprovedCategoriesKeys,
  allRejectedCategoriesKeys,
  totalAmountValidated,
  totalAmountMax,
  totalPremiumValidated,
  totalPremiumMax,
  categoryAmountApproved,
  categoryAmountMax,
  categoryAmountMin
) {
  return [
    baseCircleInfoForTotal(
      () =>
        statusForFactuurcontrole(totalAmountValidated(), 0, totalAmountMax()),
      () => progressForFactuurcontrole(totalAmountValidated(), totalAmountMax())
    ),
    baseCircleInfoForPremium(
      () =>
        statusForFactuurcontrole(totalPremiumValidated(), 0, totalPremiumMax()),
      () =>
        progressForFactuurcontrole(totalPremiumValidated(), totalPremiumMax())
    ),
    ...map(requestedCategoriesKeys, (categoryKey) =>
      baseCircleInfoForCategory(
        allApprovedCategoriesKeys,
        allRejectedCategoriesKeys,
        categoryKey,
        () =>
          statusForFactuurcontrole(
            categoryAmountApproved(categoryKey),
            categoryAmountMin(categoryKey),
            categoryAmountMax(categoryKey),
            !allApprovedCategoriesKeys[categoryKey]
          ),
        () =>
          progressForFactuurcontrole(
            categoryAmountApproved(categoryKey),
            categoryAmountMax(categoryKey),
            !allApprovedCategoriesKeys[categoryKey]
          )
      )
    ),
  ];
}

function statusForTechnischeControle(allRejectedCategoriesKeys, categoryKey) {
  return () => (allRejectedCategoriesKeys[categoryKey] ? "error" : "empty");
}
function progressForTechnischeControle(allRejectedCategoriesKeys, categoryKey) {
  return () => (allRejectedCategoriesKeys[categoryKey] ? 100 : 0);
}

function statusForFactuurcontroleBase(
  amount,
  minimum,
  maximum,
  rejected = false
) {
  if (rejected) return "error";
  else if (!amount) return "empty";
  else if (minimum && amount < minimum) return "warning";
  else if (maximum && amount >= maximum) return "success";
  else return "progress";
}

// This function can be memoized; if you pass it a certain combination of arguments, you should always get the same result
const statusForFactuurcontrole = memoize(
  statusForFactuurcontroleBase,
  (amount, minimum, maximum, rejected) =>
    `${amount}_${minimum}_${maximum}_${rejected}`
);

function progressForFactuurcontroleBase(amount, maximum, rejected = false) {
  if (rejected) return 100; // show the full circle in red if rejected
  let division = amount / maximum || 0; // in the case max is not defined, division is 0
  if (division > 0 && division < 0.01) return 1;
  // make sure a small percentage is still visually represented in the progress circle
  else return Math.min(100, Math.round(division * 100)); // rounded percentage, because the progress circles cannot handle decimals
}

// This function can be memoized; if you pass it a certain combination of arguments, you should always get the same result
const progressForFactuurcontrole = memoize(
  progressForFactuurcontroleBase,
  (amount, maximum, rejected) => `${amount}_${maximum}_${rejected}`
);

function baseCircleInfoForTotal(status = () => "empty", progress = () => 0) {
  return {
    key: "totaal_gevalideerd",
    text: "K",
    label: "Totaal gevalideerd factuurbedrag",
    isDisabled: () => false,
    status,
    progress,
  };
}

function baseCircleInfoForPremium(status = () => "empty", progress = () => 0) {
  return {
    key: "totaal_premie",
    text: "P",
    label: "Totaal te ontvangen premiebedrag",
    isDisabled: () => false,
    status,
    progress,
  };
}

function baseCircleInfoForCategory(
  allApprovedCategoriesKeys,
  allRejectedCategoriesKeys,
  categoryKey,
  status,
  progress
) {
  return {
    label: "Goedgekeurd voor " + categorieLabels[categoryKey].toLowerCase(),
    key: categoryKey,
    icon: iconsForCategory[categoryKey],
    isDisabled: () =>
      !allApprovedCategoriesKeys[categoryKey] &&
      allRejectedCategoriesKeys &&
      !allRejectedCategoriesKeys[categoryKey],
    isRejected: () =>
      allRejectedCategoriesKeys
        ? allRejectedCategoriesKeys[categoryKey]
        : !allApprovedCategoriesKeys[categoryKey],
    status,
    progress,
  };
}
