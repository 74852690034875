import angular from "angular";

import filter from "lodash/filter";
import map from "lodash/map";

import {
  getComputedExpressionValue,
  compactCircleInfoForFactuurControle,
  extendedCircleInfoForFactuurControle,
} from "@skryv/bundle/customizations/components/premiumCalculationHelpers";

import template from "./FactuurcontroleSummary.html";
import "./FactuurcontroleSummary.scss";

const namespace = "wrp/components/factuur/FactuurcontroleSummary";

// names of the computed expressions used > this changes when you change the names/keys of the computed expressions
const CE_REQUESTED_CATEGORIES = "aangevraagde_categorieen";
const CE_REQUESTED_CATEGORIES_OK = "aangevraagde_categorieen_technisch_ok";
const CE_REQUESTED_CATEGORIES_ADMINISTRATIVELY_NOK = "aangevraagde_categorieen_administratief_nok";
const CEF_CATEGORY_AMOUNT_APPROVED = "bedrag_totaal_goedgekeurd_voor";
const CEF_CATEGORY_AMOUNT_REJECTED = "bedrag_totaal_afgekeurd_voor";
const CEF_CATEGORY_MAX = "maximaal_goed_te_keuren_bedrag_voor";
const CEF_CATEGORY_MIN = "minimaal_goed_te_keuren_bedrag_voor";
const CEF_CATEGORY_PREMIUM = "premie_bedrag_voor";
const CE_TOTAL_AMOUNT_APPROVED = "goedgekeurd_totaal";
const CE_TOTAL_AMOUNT_REJECTED = "afgekeurd_totaal";
const CE_TOTAL_AMOUNT_VALIDATED = "gekeurd_totaal";
const CE_TOTAL_AMOUNT_MAX = "te_keuren_totaal";
const CE_TOTAL_PREMIUM_VALIDATED = "premie_bedrag_totaal";
const CE_TOTAL_PREMIUM_MAX = "maximum_premie_bedrag_totaal";
const CE_DOELGROEP = "doelgroep";
const CE_AANTAL_WOONEENHEDEN = "deeltal_aantal_wooneenheden";
const CE_UITSLUITEND_NACHTTARIEF = "untKlant";
const CE_SVK_SURPLUS = "int_svk_surplus";
const CE_PROPORTIONEEL_AANDEEL_TELLER = "proportioneelAandeelTeller";
const CE_PROPORTIONEEL_AANDEEL_NOEMER = "proportioneelAandeelNoemer";
const CE_RAMEN_EN_DEUREN_PRIVATIEF = "ramen_en_deuren_privatief";

angular.module(namespace, []).component("wrpFactuurcontroleSummary", {
  template,
  bindings: {
    documentModel: "<",
    withCalculations: "<",
  },
  controller: function ($timeout) {
    "ngInject";

    const $ctrl = this;

    this.$onInit = () => {
      this.showExtendedSummary = false;
      this.showCalculations = false;

      // delay loading until the other elements on the page are done; this is not critical
      $timeout(() => {
        const requestedCategoriesKeys = filter(
          map(
            getComputedExpressionValue(
              $ctrl.documentModel,
              CE_REQUESTED_CATEGORIES
            ),
            (value, key) => (value ? key : false)
          ),
          (value) => value
        );

        const administrativelyRejectedCategoriesKeys = filter(
          map(
            getComputedExpressionValue(
              $ctrl.documentModel,
              CE_REQUESTED_CATEGORIES_ADMINISTRATIVELY_NOK
            ),
            (value, key) => (value ? key : false)
          ),
          (value) => value
        );

        const requestedAndAdministrativelyOkCategories =  requestedCategoriesKeys.filter(category => !administrativelyRejectedCategoriesKeys.includes(category));

        const allApprovedCategoriesKeys = getComputedExpressionValue(
          $ctrl.documentModel,
          CE_REQUESTED_CATEGORIES_OK
        );
        const totalAmountValidated = () =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CE_TOTAL_AMOUNT_VALIDATED
          );
        const totalAmountApproved = () =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CE_TOTAL_AMOUNT_APPROVED
          );
        const totalAmountRejected = () =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CE_TOTAL_AMOUNT_REJECTED
          );
        const totalAmountMax = () =>
          getComputedExpressionValue($ctrl.documentModel, CE_TOTAL_AMOUNT_MAX);
        const totalPremiumValidated = () =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CE_TOTAL_PREMIUM_VALIDATED
          );
        const totalPremiumMax = () =>
          getComputedExpressionValue($ctrl.documentModel, CE_TOTAL_PREMIUM_MAX);
        const categoryAmountApproved = (categoryKey) =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CEF_CATEGORY_AMOUNT_APPROVED,
            categoryKey
          );
        const categoryAmountRejected = (categoryKey) =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CEF_CATEGORY_AMOUNT_REJECTED,
            categoryKey
          );
        const categoryAmountMax = (categoryKey) =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CEF_CATEGORY_MAX,
            categoryKey
          );
        const categoryAmountMin = (categoryKey) =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CEF_CATEGORY_MIN,
            categoryKey
          );
        const categoryPremium = (categoryKey) =>
          getComputedExpressionValue(
            $ctrl.documentModel,
            CEF_CATEGORY_PREMIUM,
            categoryKey
          );

        this.compactCircleInfo = compactCircleInfoForFactuurControle(
          requestedAndAdministrativelyOkCategories,
          allApprovedCategoriesKeys,
          undefined,
          totalAmountValidated,
          totalAmountMax,
          totalPremiumValidated,
          totalPremiumMax,
          categoryAmountApproved,
          categoryAmountMax,
          categoryAmountMin
        );
        this.extendedCircleInfo = extendedCircleInfoForFactuurControle(
          requestedAndAdministrativelyOkCategories,
          allApprovedCategoriesKeys,
          undefined,
          totalAmountValidated,
          totalAmountApproved,
          totalAmountRejected,
          totalAmountMax,
          totalPremiumValidated,
          totalPremiumMax,
          categoryAmountApproved,
          categoryAmountRejected,
          categoryAmountMax,
          categoryAmountMin,
          categoryPremium,
          getCalculation
        );
        this.algemeneInfo = this.getAlgemeneInfo();
      });
    };

    function getCalculation(categoryKey) {
      if (categoryKey == "gascondensatieketel") {
        return {
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_gascondensatieketel"
            ),
          premie_bedrag_voor_gascondensatieketel: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "premie_bedrag_voor_gascondensatieketel"
            ),
          basis_premie_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_gascondensatieketelDG3"
            ),
          basis_premie_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_gascondensatieketelDG2"
            ),

          bedrag_verhoging_gascondensatieketel: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "bedrag_verhoging_gascondensatieketel"
            ),
          verhoging_gascondensatieketel: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_gascondensatieketel"
            ),
          int_vervanging_stookolieketel: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "int_vervanging_stookolieketel"
            ),
        };
      }
      if (categoryKey == "binnenrenovatie") {
        return {
          max_premie_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_binnenrenovatie_dg2"
            ),
          max_premie_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_binnenrenovatie_dg3"
            ),
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_binnenrenovatie"
            ),
          aftopping_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_binnenrenovatie_dg2"
            ),
          aftopping_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_binnenrenovatie_dg3"
            ),
          aftopping: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_binnenrenovatie"
            ),
        };
      }
      if (categoryKey == "technischeInstallaties") {
        return {
          max_premie_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_elektriciteitEnSanitair_dg2"
            ),
          max_premie_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_elektriciteitEnSanitair_dg3"
            ),
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_elektriciteitEnSanitair"
            ),
          aftopping: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_elektriciteitEnSanitair"
            ),
          aftopping_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_elektriciteitEnSanitair_dg2"
            ),
          aftopping_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "aftopping_elektriciteitEnSanitair_dg3"
            ),
        };
      }
      if (categoryKey == "warmtepomp") {
        return {
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_warmtepomp"
            ),
        };
      }
      if (categoryKey == "warmtepompboiler") {
        return {
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "maximum_premie_bedrag_voor_warmtepompboiler"
            ),
        };
      }
      if (categoryKey == "zonneboiler") {
        return {
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "maximum_premie_bedrag_voor_zonneboiler"
            ),
          aftopping: () => {
            const doelgroep = getComputedExpressionValue(
              $ctrl.documentModel,
              CE_DOELGROEP
            );
            if (doelgroep == "doelgroep1")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "aftopping_zonneboiler_dg1"
              );
            if (doelgroep == "doelgroep2")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "aftopping_zonneboiler_dg2"
              );
            if (doelgroep == "doelgroep3")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "aftopping_zonneboiler_dg3"
              );
          },

          max_premie_zonneboiler: () => {
            const doelgroep = getComputedExpressionValue(
              $ctrl.documentModel,
              CE_DOELGROEP
            );
            if (doelgroep == "doelgroep1")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_zonneboiler_dg1"
              );
            if (doelgroep == "doelgroep2")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_zonneboiler_dg2"
              );
            if (doelgroep == "doelgroep3")
              return getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_zonneboiler_dg2"
              );
          },
          factorMaalOppervlakteZonneboiler: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "factor_maal_oppervlakte_zonneboiler"
            ),
        };
      }
      if (categoryKey == "muurrenovatie") {
        return {
          max_premie: () =>
            getComputedExpressionValue($ctrl.documentModel, "max_premie_muur"),
          forfaitaire_premie_muur: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_premie_muur",
              categoryKey
            ),
          max_premie_muur_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_muur_dg2",
              categoryKey
            ),
          max_premie_muur_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_muur_dg3",
              categoryKey
            ),
          forfaitaire_aftopping_muur: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_aftopping_muur",
              categoryKey
            ),
          verhoging_asbest_muur: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_asbest_muur",
              categoryKey
            ),
          verhoging_nachttarief_muur: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_nachttarief_muur",
              categoryKey
            ),
          basis_premie_muur: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_muur",
              categoryKey
            ),
          algemene_regel_dg2: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_muur_dg2",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                12000
              ) * 0.35
            );
          },
          algemene_regel_dg3: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_muur_dg3",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                12000
              ) * 0.5
            );
          },
        };
      }
      if (categoryKey == "dakrenovatie") {
        return {
          max_premie: () =>
            getComputedExpressionValue($ctrl.documentModel, "max_premie_dak"),
          forfaitaire_premie_dak: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_premie_dak",
              categoryKey
            ),
          max_premie_dak_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_isolatie_dak_dg2",
              categoryKey
            ),
          max_premie_dak_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_isolatie_dak_dg3",
              categoryKey
            ),
          forfaitaire_aftopping_dak: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_aftopping_dak",
              categoryKey
            ),
          verhoging_asbest_dak: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_asbestverwijdering_dak",
              categoryKey
            ),
          verhoging_nachttarief_dak: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_nachttarief_dak",
              categoryKey
            ),
          basis_premie_dak: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_dak",
              categoryKey
            ),
          algemene_regel_dg2: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_isolatie_dak_dg2",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                11500
              ) * 0.35
            );
          },
          algemene_regel_dg3: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_isolatie_dak_dg3",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                11500
              ) * 0.5
            );
          },
        };
      }
      if (categoryKey == "vloerrenovatie") {
        return {
          max_premie: () =>
            getComputedExpressionValue($ctrl.documentModel, "max_premie_vloer"),
          forfaitaire_premie_vloer: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_premie_vloer",
              categoryKey
            ),
          max_premie_vloer_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_vloer_dg2",
              categoryKey
            ),
          max_premie_vloer_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_vloer_dg3",
              categoryKey
            ),
          forfaitaire_aftopping_vloer: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_aftopping_vloer",
              categoryKey
            ),
          verhoging_nachttarief_vloer: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_nachttarief_vloer",
              categoryKey
            ),
          basis_premie_vloer: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_vloer",
              categoryKey
            ),
          algemene_regel_dg2: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_vloer_dg2",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                3000
              ) * 0.35
            );
          },
          algemene_regel_dg3: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_vloer_dg3",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                3000
              ) * 0.5
            );
          },
        };
      }
      if (categoryKey == "buitenschrijnwerkGlas") {
        return {
          max_premie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_ramen_en_deuren"
            ),
          forfaitaire_premie_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_premie_ramen_en_deuren",
              categoryKey
            ),
          forfaitaire_premie_ramen_en_deuren_geen_ventilatie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_premie_ramen_en_deuren_geen_ventilatie",
              categoryKey
            ),
          max_premie_ramen_en_deuren_dg2: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_ramen_en_deuren_dg2",
              categoryKey
            ),
          max_premie_ramen_en_deuren_dg3: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "max_premie_ramen_en_deuren_dg3",
              categoryKey
            ),
          forfaitaire_aftopping_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "forfaitaire_aftopping_ramen_en_deuren",
              categoryKey
            ),
          verhoging_nachttarief_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "verhoging_nachttarief_ramen_en_deuren",
              categoryKey
            ),
          basis_premie_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "basis_premie_ramen_en_deuren",
              categoryKey
            ),
          totaal_goedgekeurd_voor_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "totaal_goedgekeurd_voor_ramen_en_deuren",
              categoryKey
            ),
          algemene_regel_dg2: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_ramen_en_deuren_dg2",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                11000
              ) * 0.35
            );
          },
          algemene_regel_dg3: () => {
            return Math.min(
              getComputedExpressionValue(
                $ctrl.documentModel,
                "max_premie_ramen_en_deuren_dg3",
                categoryKey
              ),
              Math.min(
                getComputedExpressionValue(
                  $ctrl.documentModel,
                  CEF_CATEGORY_AMOUNT_APPROVED,
                  categoryKey
                ),
                11000
              ) * 0.5
            );
          },
          int_geen_ventilatie_voorwaarde_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "int_geen_ventilatie_voorwaarde_ramen_en_deuren"
            ),
          bedrag_totaal_afgekeurd_door_ventilatie: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "bedrag_totaal_afgekeurd_door_ventilatie"
            ),
          factor_oppervlakte_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "factor_oppervlakte_ramen_en_deuren"
            ),
          oppervlakte_ramen_en_deuren: () =>
            getComputedExpressionValue(
              $ctrl.documentModel,
              "oppervlakte_ramen_en_deuren"
            ),
        };
      }

      return {};
    }

    this.getAlgemeneInfo = () => {
      return {
        doelgroep: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_DOELGROEP
        ),
        aantalWooneenheden: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_AANTAL_WOONEENHEDEN
        ),
        uitsluitendNachttarief: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_UITSLUITEND_NACHTTARIEF
        ),
        int_svk_surplus: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_SVK_SURPLUS
        ),
        int_svk_surplus_ramen_en_deuren:
          getComputedExpressionValue($ctrl.documentModel, CE_SVK_SURPLUS) &&
          !getComputedExpressionValue(
            $ctrl.documentModel,
            CE_RAMEN_EN_DEUREN_PRIVATIEF
          ),
        proportioneel_aandeel_teller: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_PROPORTIONEEL_AANDEEL_TELLER
        ),
        proportioneel_aandeel_noemer: getComputedExpressionValue(
          $ctrl.documentModel,
          CE_PROPORTIONEEL_AANDEEL_NOEMER
        ),
      };
    };
  },
});

export { template };
export default namespace;
