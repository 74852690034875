import angular from "angular";

import { searchNames } from "@skryv/core-ng1/config/search";
import { parseFieldDefinition } from "@skryv/core-ng1/services/search/searchESFields";

import template from "@skryv/core-ng1/components/search/DossierSearch/DossierSearch.html";

const namespace = "wrp/components/dashboard/MyDossiersDashboard";

angular.module(namespace, []).component("wrpMyDossiersDashboard", {
  template,
  controller: function (advancedSearch, userService) {
    "ngInject";

    // define columns for document search
    this.dossierColumnDefinitions = [
      {
        heading: "Dossier",
        select: ["dossier.label", "dossier.id"],
        field: "dossier.label",
        type: "search/keyword",
        state: () => "dossier",
        stateParams: (cell, searchHit) => {
          const dossierId =
            parseFieldDefinition("dossier.id").extract(searchHit)[0];
          return { dossierId };
        },
      },
      {
        heading: "Aanvraagdatum",
        key: "aanvraagdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
        field: {
          path: "document.aanvraag.aanvraagdatum",
          options: { nesting: "document" },
        },
      },
      {
        heading: "Latest milestone",
        type: "search/keyword",
        field: "lastMilestone.status",
      },
      {
        heading: "Alle mijlpalen",
        sort: null,
        field: "milestones.status",
      },
      {
        heading: "Mijn taken",
        key: "myTasks",
        select: ["dossier.task"],
        field: {
          path: "task.name",
          options: { nesting: "task" },
        },
        sort: false,
        filter: false,
        type: "search/keyword",
        content: `lookupAll('task',item).filter(function(task) { return task.assignee === '${userService.getCurrentUserSub()}'; }).map(function(task) { return task.name})`,
      },
      {
        heading: "Aangevraagde categorieën",
        key: "categorieen",
        type: "search/document/multichoice",
        sort: false,
        field: {
          path: "document.aanvraag.selectieCategorieen.geselecteerdeCatgeorieen",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "binnenrenovatie",
            label: "Binnenrenovatie",
          },
          {
            name: "muurrenovatie",
            label: "Buitenmuur",
          },
          {
            name: "dakrenovatie",
            label: "Dak",
          },
          {
            name: "technischeInstallaties",
            label: "Elektriciteit en sanitair",
          },
          {
            name: "gascondensatieketel",
            label: "Gascondensatieketel",
          },
          {
            name: "buitenschrijnwerkGlas",
            label: "Ramen en deuren",
          },
          {
            name: "vloerrenovatie",
            label: "Vloer",
          },
          {
            name: "warmtepomp",
            label: "Warmtepomp",
          },
          {
            name: "warmtepompboiler",
            label: "Warmtepompboiler",
          },
          {
            name: "zonneboiler",
            label: "Zonneboiler",
          },
        ],
      },
      {
        heading: "Naam aanvrager",
        key: "aanvrager",
        type: "search/document/text",
        sort: false,
        filter: false,
        field: {
          path: "document.aanvraag.naamVanDeAanvrager",
          options: { nesting: "document" },
        },
        content: `lookupAll('document.loginData',item).map(function(item) { return item.naamOrganisatie ? item.naamOrganisatie : item.voornaam + " " + item.naam})`,
      },
      {
        heading: "Uitvoeringsadres",
        key: "uitvoeringsadres",
        type: "search/document/text",
        sort: false,
        field: {
          path: "document.aanvraag.uitvoeringsAdresVolledig",
          options: { nesting: "document" },
        },
      },
      {
        heading: "Type aanvrager",
        key: "typeAanvrager",
        type: "search/document/choice",
        sort: false,
        field: {
          path: "document.CategorieenStatus.typeAanvrager",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "eigenaarBewoner",
            label: "Eigenaar-bewoner",
          },
          {
            name: "svkVerhuurder",
            label: "SVK-verhuurder",
          },
          {
            name: "onderneming",
            label: "Onderneming",
          },
          {
            name: "vme",
            label: "VME",
          },
          {
            name: "onbekend",
            label: "Andere",
          },
          {
            name: "huurder",
            label: "Huurder",
          },
          {
            name: "externeInvesteerder",
            label: "Enterne investeerder",
          },
        ],
      },
      {
        heading: "Type gebouw",
        key: "typeGebouw",
        type: "search/document/choice",
        sort: false,
        field: {
          path: "document.CategorieenStatus.typeGebouw",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "appartement",
            label: "Appartement",
          },
          {
            name: "eengezinswoning",
            label: "Eéngezinswoning",
          },
          {
            name: "appartementsgebouw",
            label: "Appartementsgebouw",
          },
          {
            name: "nietResidentieel",
            label: "Niet residentieel",
          },
        ],
      },
      {
        heading: "In beroep",
        key: "dossierInBeroep",
        sort: false,
        type: "search/document/choice",
        field: {
          path: "document.CategorieenStatus.dossierInBeroep",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
      {
        heading: "Is heropend",
        key: "dossierHeropend",
        type: "search/document/choice",
        sort: false,
        field: {
          path: "document.CategorieenStatus.dossierIsHeropend",
          options: { nesting: "document" },
        },
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
    ];

    this.views = [
      {
        name: "Default",
        columns: [
          "dossier.label",
          "aanvraagdatum",
          "lastMilestone.status",
          "myTasks",
          "categorieen",
          "uitvoeringsadres",
          // "milestones.status",
          // "aanvrager"
          // "typeAanvrager"
          // "typeGebouw",
          "dossierInBeroep",
          "dossierHeropend",
        ],
        filters: [],
        sort: {
          criterion: "document.aanvraag.aanvraagdatum",
          desc: false,
        },
      },
    ];

    // there exists a task where I am the assignee
    this.filtersToNest = [
      {
        field: parseFieldDefinition("task.assignee"),
        value: userService.getCurrentUserSub(),
      },
    ];

    this.searchDefinition = {
      key: "search/dossiers/v2",
      label: "dossiers",
      datasourceType: "es/dossiers",
      columnDefinitions: this.dossierColumnDefinitions,
      views: this.views,
      advancedSearch: advancedSearch.getAdvancedSearchConfigurationForKey(
        searchNames.DASHBOARD_DOSSIERS
      ),
      hiddenFilters: [
        { nested: { path: "task", queries: this.filtersToNest } },
      ],
    };
  },
});

export { template };
export default namespace;
