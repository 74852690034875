import angular from "angular";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import map from "lodash/map";
import immutable from "seamless-immutable";

import { fetchDossierComments } from "@skryv/core-ng1/core/store/actions/dossiercomments";
import {
  selectDossierDetails,
  selectMilestonesForDossier,
} from "@skryv/core-ng1/core/store/selectors/dossiers";
import { selectPinnedDossierComments } from "@skryv/core-ng1/core/store/selectors/dossiercomments";
import {
  fetchDossierDetails,
  fetchDossierTasks,
  pollForDossierTasks,
} from "@skryv/core-ng1/core/store/actions/dossiers";
import trustedFilter from "@skryv/core-ng1/components/form/filters/trustedFilter.js";

import documentDefinitionKeys from "@skryv/bundle/customizations/constants/documentDefinitionKeys";
import milestoneDefinitionKeys from "@skryv/bundle/customizations/constants/milestoneDefinitionKeys";
import * as taskDefinitionKeys from "@skryv/bundle/customizations/constants/taskDefinitionKeys";

import {
  extendedCircleInfoForTechnischeControle,
  extendedCircleInfoForFactuurControle,
} from "@skryv/bundle/customizations/components/premiumCalculationHelpers";

import {
  formatAddress,
  formatCategories,
  formatDate,
  getActiveTasks,
  getDocument,
  getField,
  getProcessInformation,
  goToDossierPage,
  hasSomeOfMilestones,
  goToTaskPage,
  getLatestMilestone,
} from "./dossierOverviewHelpers";

import template from "./DossierOverview.html";
import "./DossierOverview.scss";

const namespace = "wrp/components/dossier/DossierOverview";

angular
  .module(namespace, [])
  .component("wrpDossierPage", {
    template,
    bindings: {
      apiDossier: "<",
      dosdef: "<",
    },
    controller: function ($ngRedux, $filter, $state, userService) {
      "ngInject";
      const $ctrl = this;
      const disconnect = $ngRedux.connect(mapStateToThis, {
        fetchDossierComments,
        fetchDossierDetails,
        fetchDossierTasks,
        pollForDossierTasks,
      })(this);
      this.$onDestroy = () => {
        disconnect();
        this.taskPoller && this.taskPoller.stopPolling();
      };

      this.$onInit = () => {
        this.processStepsOpen = false;
        this.dossierId = get(this.apiDossier, ["id"]);
        this.noTasksLabel = "No tasks present";

        this.loadingInitialDetails = true;
        this.fetchDossierDetails(this.dossierId).then(() => {
          this.loadingInitialDetails = false;
          this.taskPoller = this.pollForDossierTasks({
            dossierId: this.dossierId,
            stopOnAssignee: userService.getCurrentUserSub(),
          });
        });
        this.fetchDossierComments(this.dossierId);
      };

      this.assignmentStart = () => {
        // Stop polling as soon as the user starts reassigning tasks, to avoid race conditions
        // between task polling and task reloading after reassignments.
        this.taskPoller && this.taskPoller.stopPolling();
      };

      this.assigneeChanged = () => {
        this.fetchDossierTasks(this.dossierId);
      };

      this.taskPollingOngoing = () =>
        // If there is no task poller yet, it means it still has to be created (and it will),
        // so we consider task polling to be ongoing already. Otherwise, the UI may flash for
        // a moment that there are no tasks at all.
        !this.taskPoller || this.taskPoller.isBusy();

      this.goToHistoryPage = () =>
        goToDossierPage(this.dossierId, "dossierHistory", $state);

      function checkMilestonesForPremiumCalculations(
        allMilestones,
        dossierDetails
      ) {
        const latestMilestone = getLatestMilestone(allMilestones);
        if (!latestMilestone) return null;
        return latestMilestone.key !=
          milestoneDefinitionKeys.GO_BACK_TO_ADVICE &&
          latestMilestone.key !=
            milestoneDefinitionKeys.ADVICE_CONTROL_STARTED &&
          latestMilestone.key !=
            milestoneDefinitionKeys.BULK_ADMIN_CONTROL_STARTED &&
          latestMilestone.key !=
            milestoneDefinitionKeys.ADVICE_CONTROL_STARTED_BEROEP
          ? hasSomeOfMilestones(allMilestones, [
              milestoneDefinitionKeys.TECHNICAL_CONTROL_STARTED,
              milestoneDefinitionKeys.FINANCIAL_CONTROL_STARTED,
            ])
            ? getPremiumCalculations(dossierDetails, allMilestones)
            : null
          : null;
      }

      function mapStateToThis(state) {
        const dossierDetails = selectDossierDetails(state, $ctrl.dossierId);
        const allMilestones = selectMilestonesForDossier(
          state,
          $ctrl.dossierId
        );
        let infoFromDossierDetails = {};

        if (dossierDetails) {
          infoFromDossierDetails = {
            dossierDetails,
            defaultDossierInformation:
              defaultDossierInformation(dossierDetails),
            treatmentDossierInformation: hasSomeOfMilestones(allMilestones, [
              milestoneDefinitionKeys.ADVICE_CONTROL_STARTED,
              milestoneDefinitionKeys.AUTHENTIC_DATA_STARTED,
            ])
              ? treatmentDossierInformation(dossierDetails, allMilestones)
              : null,
            processInformation: getProcessInformation(dossierDetails, $state),
            activeTasks: getActiveTasks(dossierDetails),
            premiumCalculations: checkMilestonesForPremiumCalculations(
              allMilestones,
              dossierDetails
            ),
            noTasksLabel: hasSomeOfMilestones(allMilestones, [
              milestoneDefinitionKeys.DOSSIER_DEF_REJECTED,
              milestoneDefinitionKeys.DOSSIER_DEF_APPROVED,
            ])
              ? "No tasks present"
              : "Nog geen taak gevonden",
            admQualityControlComment:
              getAdmQualityControlComment(dossierDetails),
            beslQualityControlComment:
              getBeslQualityControlComment(dossierDetails),
          };
        }
        return Object.assign({}, infoFromDossierDetails, {
          pinnedComments: immutable.asMutable(
            selectPinnedDossierComments(state, $ctrl.dossierId),
            { deep: true }
          ),
          allMilestones: allMilestones,
          contactMilestones: filter(allMilestones, {
            key: milestoneDefinitionKeys.CONTACT_FORM_SUBMITTED,
          }),
        });
      }

      const defaultDossierInformation = (dossierDetails) => {
        if (!dossierDetails) return;

        const aanvraagDoc = getDocument(
          documentDefinitionKeys.AANVRAAG,
          dossierDetails
        );
        function getFromAanvraag(fieldPath) {
          return getField(
            aanvraagDoc,
            documentDefinitionKeys.AANVRAAG,
            fieldPath
          );
        }
        if (!aanvraagDoc) return;

        const loginDoc = getDocument("loginData", dossierDetails);
        function getFromLoginData(fieldPath) {
          return getField(loginDoc, "loginData", fieldPath);
        }
        if (!loginDoc) return;

        return [
          {
            label: "Uitvoeringsadres",
            value: formatAddress(getFromAanvraag("uitvoeringsadres")),
          },
          {
            label: "Aanvraagdatum",
            value: formatDate(getFromAanvraag("aanvraagdatum")),
          },
          {
            label: "EAN",
            value:
              "54144" + getFromAanvraag(["eanAndAddress", "eanCodeWoning"]),
          },
          {
            label: "Aanvrager",
            value:
              getFromLoginData("naamOrganisatie") !== "- niet ingevuld -" &&
              getFromLoginData("type") !== "CITIZEN"
                ? getFromLoginData("naamOrganisatie")
                : `${getFromAanvraag("voornaam")} ${getFromAanvraag("naam")}`,
          },
          {
            label: "Aangevraagde categorieën",
            value: formatCategories(
              getFromAanvraag([
                "selectieCategorieen",
                "geselecteerdeCatgeorieen",
                "selectedOptionsLabels",
              ])
            ),
          },
          {
            value: "Bekijk Aanvraag",
            action: () =>
              goToTaskPage(
                taskDefinitionKeys.WRP_AANVRAAG,
                dossierDetails,
                $state
              ),
          },
        ];
      };

      const treatmentDossierInformation = (dossierDetails, allMilestones) => {
        if (!dossierDetails) return;

        const adviesDoc = getDocument(
          documentDefinitionKeys.ADVIES,
          dossierDetails
        );
        function getFromAdvies(fieldPath) {
          return getField(adviesDoc, documentDefinitionKeys.ADVIES, fieldPath);
        }

        const authDataDoc = getDocument(
          documentDefinitionKeys.AUTHENTIEKE_DATA,
          dossierDetails
        );
        function getFromAuthData(fieldPath) {
          return getField(
            authDataDoc,
            documentDefinitionKeys.AUTHENTIEKE_DATA,
            fieldPath
          );
        }

        const catStatDoc = getDocument(
          documentDefinitionKeys.CAT_STATUS,
          dossierDetails
        );
        function getFromCatStat(fieldPath) {
          return getField(
            catStatDoc,
            documentDefinitionKeys.CAT_STATUS,
            fieldPath
          );
        }

        const attestDoc = getDocument(
          documentDefinitionKeys.ATTEST_CONTROLE,
          dossierDetails
        );
        function getFromAttest(fieldPath) {
          if (!attestDoc) return undefined;
          return getField(
            attestDoc,
            documentDefinitionKeys.ATTEST_CONTROLE,
            fieldPath,
            false
          );
        }

        let relatedDossiers = [];
        if (
          hasSomeOfMilestones(
            allMilestones,
            milestoneDefinitionKeys.AUTHENTIC_DATA_FETCHED
          )
        ) {
          relatedDossiers = [
            {
              label: "Gerelateerde dossiers",
              value: mapRelatedDossiers(),
            },
          ];
        }

        function mapRelatedDossiers() {
          let cumulDossiers = map(
            getFromAuthData(["cumulDossiers", "elements"]),
            (relatedDossier) =>
              `${relatedDossier.dossierLabel} (${get(relatedDossier, [
                "status",
                "selectedOptionLabel",
                "nl",
              ])})`
          );

          let cumulDigiReno = map(
            getFromAuthData(["cumulDossiersDigiReno", "elements"]),
            (relatedDossier) =>
              `${relatedDossier.dossierLabel} (${get(relatedDossier, [
                "status",
                "selectedOptionLabel",
                "nl",
              ])})`
          );

          let joinedDossiersWithoutCheckArray = [
            ...cumulDossiers,
            ...cumulDigiReno,
          ].join("\n");
          let joinedDossiersWithoutCheckStr =
            joinedDossiersWithoutCheckArray.split("\n");
          let joinedDossiersArray = [];
          joinedDossiersWithoutCheckStr.forEach(
            removeDossiersWithStatusVoorlopig
          );

          function removeDossiersWithStatusVoorlopig(
            joinedDossiersWithoutCheckStr
          ) {
            if (!joinedDossiersWithoutCheckStr.includes("voorlopig")) {
              joinedDossiersArray.push(joinedDossiersWithoutCheckStr);
            }
          }

          let joinedDossiers = joinedDossiersArray.join("\n");
          return joinedDossiers
            ? joinedDossiers
            : "Geen gerelateerde dossiers gevonden";
        }

        return [
          {
            label: "Doelgroep",
            value: getFromAdvies(["doelgroep", "selectedOptionLabel", "nl"]),
          },
          {
            label: "UNT",
            value: getFromAuthData("uitsluitendNachttarief") ? "Ja" : "Nee",
          },
          {
            label: "Beschermde afnemer",
            value: getFromAttest("isBeschermdeAfnemer") ? "Ja" : "Nee",
          },
          {
            label: "Type gebouw",
            value: getFromCatStat(["typeGebouw", "selectedOptionLabel", "nl"]),
          },
          {
            label: "Type aanvrager",
            value: getFromCatStat([
              "typeAanvrager",
              "selectedOptionLabel",
              "nl",
            ]),
          },
          {
            value: linksToTreatmentDocuments(allMilestones, dossierDetails),
          },
          ...relatedDossiers,
        ];
      };

      //TODO redirect to task

      function linksToTreatmentDocuments(allMilestones, dossierDetails) {
        const links = [];
        if (
          hasSomeOfMilestones(
            allMilestones,
            milestoneDefinitionKeys.ADVICE_CONTROL_STARTED
          )
        ) {
          links.push({
            value: "Bekijk Automatisch advies\n",
            action: () =>
              goToTaskPage(
                taskDefinitionKeys.WRP_ADVIES_CONTROLE,
                dossierDetails,
                $state
              ),
          });
        }
        if (
          hasSomeOfMilestones(
            allMilestones,
            milestoneDefinitionKeys.TECHNICAL_CONTROL_STARTED
          )
        ) {
          links.push({
            value: "Bekijk Technische controle\n",
            action: () =>
              goToTaskPage(
                taskDefinitionKeys.WRP_TECHNISCHE_CONTROLE,
                dossierDetails,
                $state
              ),
          });
        }
        if (
          hasSomeOfMilestones(
            allMilestones,
            milestoneDefinitionKeys.FINANCIAL_CONTROL_STARTED
          )
        ) {
          links.push({
            value: "Bekijk Financiële controle",
            action: () =>
              goToTaskPage(
                taskDefinitionKeys.WRP_FINANCIELE_CONTROLE,
                dossierDetails,
                $state
              ),
          });
        }
        return links;
      }

      const getPremiumCalculations = (dossierDetails, allMilestones) => {
        if (!dossierDetails) return;

        const aanvraagDoc = getDocument(
          documentDefinitionKeys.AANVRAAG,
          dossierDetails
        );
        function getFromAanvraag(fieldPath) {
          return getField(
            aanvraagDoc,
            documentDefinitionKeys.AANVRAAG,
            fieldPath
          );
        }
        if (!aanvraagDoc) return;

        const categorieenStatusDoc = getDocument(
          documentDefinitionKeys.CAT_STATUS,
          dossierDetails
        );
        function getFromCategorieenStatus(fieldPath) {
          return getField(
            categorieenStatusDoc,
            documentDefinitionKeys.CAT_STATUS,
            fieldPath
          );
        }
        if (!categorieenStatusDoc) return;

        const technischeControleDoc = getDocument(
          documentDefinitionKeys.TECHNISCHE_CONTROLE,
          dossierDetails
        );
        function getFromTechnischeControle(fieldPath) {
          return getField(
            technischeControleDoc,
            documentDefinitionKeys.TECHNISCHE_CONTROLE,
            fieldPath
          );
        }
        if (!technischeControleDoc) return;

        const factuurcontroleDoc = getDocument(
          documentDefinitionKeys.FINANCIELE_CONTROLE,
          dossierDetails
        );
        function getFromFactuurcontrole(fieldPath) {
          return getField(
            factuurcontroleDoc,
            documentDefinitionKeys.FINANCIELE_CONTROLE,
            fieldPath,
            0
          );
        }

        if (
          !hasSomeOfMilestones(allMilestones, [
            milestoneDefinitionKeys.FINANCIAL_CONTROL_STARTED,
          ])
        )
          return premiumCalculationsForTechnischeControle(
            getFromAanvraag,
            getFromTechnischeControle,
            getFromCategorieenStatus
          );
        else
          return premiumCalculationsForFactuurcontrole(
            getFromAanvraag,
            getFromFactuurcontrole,
            getFromTechnischeControle,
            getFromCategorieenStatus
          );
      };

      function premiumCalculationsForTechnischeControle(
        getFromAanvraag,
        getFromTechnischeControle,
        getFromCategorieenStatus
      ) {
        const FIELD_REQUESTED_CATEGORIES = [
          "selectieCategorieen",
          "geselecteerdeCatgeorieen",
          "selectedOptions",
        ];

        const FIELD_ADMINISTRATIVELY_REJECTED_CATEGORIES = [
          "administrativelyRejected",
          "selectedOptions",
        ];

        const FIELD_REQUESTED_CATEGORIES_OK = [
          "computations",
          "technicallyApprovedCategorieen",
          "selectedOptions",
        ];
        const FIELD_REQUESTED_CATEGORIES_NOK = [
          "computations",
          "technicallyRejectedCategorieen",
          "selectedOptions",
        ];

        const requestedCategoriesKeys = getFromAanvraag(
          FIELD_REQUESTED_CATEGORIES
        );

        const administrativelyRejectedCategoriesKeys = getFromCategorieenStatus(
          FIELD_ADMINISTRATIVELY_REJECTED_CATEGORIES
        );

        const technicalOkCategoriesKeys = getFromTechnischeControle(
          FIELD_REQUESTED_CATEGORIES_OK
        );

        const technicalNokCategoriesKeys = getFromTechnischeControle(
          FIELD_REQUESTED_CATEGORIES_NOK
        );

        const requestedAndAdministrativelyOkCategories =
          requestedCategoriesKeys.filter(
            (category) =>
              !administrativelyRejectedCategoriesKeys.includes(category)
          );

        return extendedCircleInfoForTechnischeControle(
          requestedAndAdministrativelyOkCategories,
          reduce(
            requestedAndAdministrativelyOkCategories,
            (acc, categoryKey) => {
              return Object.assign(acc, {
                [categoryKey]: includes(technicalOkCategoriesKeys, categoryKey),
              });
            },
            {}
          ),
          reduce(
            requestedAndAdministrativelyOkCategories,
            (acc, categoryKey) => {
              return Object.assign(acc, {
                [categoryKey]: includes(
                  technicalNokCategoriesKeys,
                  categoryKey
                ),
              });
            },
            {}
          )
        );
      }

      function premiumCalculationsForFactuurcontrole(
        getFromAanvraag,
        getFromFactuurcontrole,
        getFromTechnischeControle,
        getFromCategorieenStatus
      ) {
        const FIELD_REQUESTED_CATEGORIES = [
          "selectieCategorieen",
          "geselecteerdeCatgeorieen",
          "selectedOptions",
        ];

        const FIELD_ADMINISTRATIVELY_REJECTED_CATEGORIES = [
          "administrativelyRejected",
          "selectedOptions",
        ];

        const FIELD_REQUESTED_CATEGORIES_OK = [
          "computations",
          "technicallyApprovedCategorieen",
          "selectedOptions",
        ];
        const FIELD_REQUESTED_CATEGORIES_NOK = [
          "computations",
          "technicallyRejectedCategorieen",
          "selectedOptions",
        ];
        const FIELD_TOTAL_AMOUNT = ["computations", "totaalGevalideerd"];
        const FIELD_TOTAL_AMOUNT_APPROVED = [
          "computations",
          "totaalGoedgekeurd",
        ];
        const FIELD_TOTAL_AMOUNT_REJECTED = ["computations", "totaalAfgekeurd"];
        const FIELD_TOTAL_AMOUNT_MAX = ["computations", "totaalTeKeuren"];
        const FIELD_TOTAL_PREMIUM = ["computations", "totalePremie"];
        const FIELD_TOTAL_PREMIUM_MAX = ["computations", "totalePremieMax"];
        const FIELD_CATEGORY_APPROVED = {
          binnenrenovatie: [
            "computations",
            "goedgekeurdPerCategorie",
            "binnenrenovatie",
          ],
          buitenschrijnwerkGlas: [
            "computations",
            "goedgekeurdPerCategorie",
            "buitenschrijnwerkGlas",
          ],
          dakrenovatie: [
            "computations",
            "goedgekeurdPerCategorie",
            "dakrenovatie",
          ],
          gascondensatieketel: [
            "computations",
            "goedgekeurdPerCategorie",
            "gascondensatieketel",
          ],
          muurrenovatie: [
            "computations",
            "goedgekeurdPerCategorie",
            "muurrenovatie",
          ],
          technischeInstallaties: [
            "computations",
            "goedgekeurdPerCategorie",
            "technischeInstallaties",
          ],
          vloerrenovatie: [
            "computations",
            "goedgekeurdPerCategorie",
            "vloerrenovatie",
          ],
          warmtepomp: ["computations", "goedgekeurdPerCategorie", "warmtepomp"],
          warmtepompboiler: [
            "computations",
            "goedgekeurdPerCategorie",
            "warmtepompboiler",
          ],
          zonneboiler: [
            "computations",
            "goedgekeurdPerCategorie",
            "zonneboiler",
          ],
        };
        const FIELD_CATEGORY_REJECTED = {
          binnenrenovatie: [
            "computations",
            "afgekeurdPerCategorie",
            "binnenrenovatie",
          ],
          buitenschrijnwerkGlas: [
            "computations",
            "afgekeurdPerCategorie",
            "buitenschrijnwerkGlas",
          ],
          dakrenovatie: [
            "computations",
            "afgekeurdPerCategorie",
            "dakrenovatie",
          ],
          gascondensatieketel: [
            "computations",
            "afgekeurdPerCategorie",
            "gascondensatieketel",
          ],
          muurrenovatie: [
            "computations",
            "afgekeurdPerCategorie",
            "muurrenovatie",
          ],
          technischeInstallaties: [
            "computations",
            "afgekeurdPerCategorie",
            "technischeInstallaties",
          ],
          vloerrenovatie: [
            "computations",
            "afgekeurdPerCategorie",
            "vloerrenovatie",
          ],
          warmtepomp: ["computations", "afgekeurdPerCategorie", "warmtepomp"],
          warmtepompboiler: [
            "computations",
            "afgekeurdPerCategorie",
            "warmtepompboiler",
          ],
          zonneboiler: ["computations", "afgekeurdPerCategorie", "zonneboiler"],
        };
        const FIELD_CATEGORY_MIN = {
          binnenrenovatie: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "binnenrenovatie",
          ],
          buitenschrijnwerkGlas: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "buitenschrijnwerkGlas",
          ],
          dakrenovatie: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "dakrenovatie",
          ],
          gascondensatieketel: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "gascondensatieketel",
          ],
          muurrenovatie: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "muurrenovatie",
          ],
          technischeInstallaties: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "technischeInstallaties",
          ],
          vloerrenovatie: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "vloerrenovatie",
          ],
          warmtepomp: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "warmtepomp",
          ],
          warmtepompboiler: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "warmtepompboiler",
          ],
          zonneboiler: [
            "computations",
            "minimaalTeKeurenPerCategorie",
            "zonneboiler",
          ],
        };
        const FIELD_CATEGORY_MAX = {
          binnenrenovatie: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "binnenrenovatie",
          ],
          buitenschrijnwerkGlas: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "buitenschrijnwerkGlas",
          ],
          dakrenovatie: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "dakrenovatie",
          ],
          gascondensatieketel: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "gascondensatieketel",
          ],
          muurrenovatie: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "muurrenovatie",
          ],
          technischeInstallaties: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "technischeInstallaties",
          ],
          vloerrenovatie: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "vloerrenovatie",
          ],
          warmtepomp: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "warmtepomp",
          ],
          warmtepompboiler: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "warmtepompboiler",
          ],
          zonneboiler: [
            "computations",
            "maximaalTeKeurenPerCategorie",
            "zonneboiler",
          ],
        };
        const FIELD_CATEGORY_PREMIUM = {
          binnenrenovatie: ["computations", "premieBinnenrenovatie"],
          buitenschrijnwerkGlas: ["computations", "premieRamenEnDeuren"],
          dakrenovatie: ["computations", "premieDak"],
          gascondensatieketel: ["computations", "premieGascondensatieketel"],
          muurrenovatie: ["computations", "premieMuur"],
          technischeInstallaties: [
            "computations",
            "premieBElektriciteitEnSanitair",
          ],
          vloerrenovatie: ["computations", "premieVloer"],
          warmtepomp: ["computations", "premieWarmtepomp"],
          warmtepompboiler: ["computations", "premieWarmtepompboiler"],
          zonneboiler: ["computations", "premieZonneboiler"],
        };

        const requestedCategoriesKeys = getFromAanvraag(
          FIELD_REQUESTED_CATEGORIES
        );

        const administrativelyRejectedCategoriesKeys = getFromCategorieenStatus(
          FIELD_ADMINISTRATIVELY_REJECTED_CATEGORIES
        );

        const technicalNokCategoriesKeys = getFromTechnischeControle(
          FIELD_REQUESTED_CATEGORIES_NOK
        );

        const technicalOkCategoriesKeys = getFromTechnischeControle(
          FIELD_REQUESTED_CATEGORIES_OK
        );

        const requestedAndAdministrativelyOkCategories =
          requestedCategoriesKeys.filter(
            (category) =>
              !administrativelyRejectedCategoriesKeys.includes(category)
          );

        return extendedCircleInfoForFactuurControle(
          requestedAndAdministrativelyOkCategories,
          reduce(
            requestedAndAdministrativelyOkCategories,
            (acc, categoryKey) => {
              return Object.assign(acc, {
                [categoryKey]: includes(technicalOkCategoriesKeys, categoryKey),
              });
            },
            {}
          ),
          reduce(
            requestedAndAdministrativelyOkCategories,
            (acc, categoryKey) => {
              return Object.assign(acc, {
                [categoryKey]: includes(
                  technicalNokCategoriesKeys,
                  categoryKey
                ),
              });
            },
            {}
          ),
          () => getFromFactuurcontrole(FIELD_TOTAL_AMOUNT),
          () => getFromFactuurcontrole(FIELD_TOTAL_AMOUNT_APPROVED),
          () => getFromFactuurcontrole(FIELD_TOTAL_AMOUNT_REJECTED),
          () => getFromFactuurcontrole(FIELD_TOTAL_AMOUNT_MAX),
          () => getFromFactuurcontrole(FIELD_TOTAL_PREMIUM),
          () => getFromFactuurcontrole(FIELD_TOTAL_PREMIUM_MAX),
          (categoryKey) =>
            getFromFactuurcontrole(FIELD_CATEGORY_APPROVED[categoryKey]),
          (categoryKey) =>
            getFromFactuurcontrole(FIELD_CATEGORY_REJECTED[categoryKey]),
          (categoryKey) =>
            getFromFactuurcontrole(FIELD_CATEGORY_MAX[categoryKey]),
          (categoryKey) =>
            getFromFactuurcontrole(FIELD_CATEGORY_MIN[categoryKey]),
          (categoryKey) =>
            getFromFactuurcontrole(FIELD_CATEGORY_PREMIUM[categoryKey]),
          () => {}
        );
      }

      function getAdmQualityControlComment(dossierDetails) {
        const admQualityControlDoc = getDocument(
          documentDefinitionKeys.ADM_KWALITEITSCONTROLE,
          dossierDetails
        );
        const FIELD_ADM_QC_COMMENT = [
          "opmerkingenAdministratieveKwaliteitscontrole",
        ];
        const admQCComment = getField(
          admQualityControlDoc,
          documentDefinitionKeys.ADM_KWALITEITSCONTROLE,
          FIELD_ADM_QC_COMMENT,
          ""
        );

        if (
          !admQCComment ||
          !admQCComment.value ||
          !admQCComment.value.length > 0
        )
          return;
        return {
          ...admQCComment,
          updatedAt: formatDate(admQualityControlDoc.updatedAt),
        };
      }
      function getBeslQualityControlComment(dossierDetails) {
        const beslQualityControlDoc = getDocument(
          documentDefinitionKeys.BESL_KWALITEITSCONTROLE,
          dossierDetails
        );
        const FIELD_BESL_QC_COMMENT = [
          "opmerkingenBeslissingKwaliteitscontrole",
        ];
        const beslQCComment = getField(
          beslQualityControlDoc,
          documentDefinitionKeys.BESL_KWALITEITSCONTROLE,
          FIELD_BESL_QC_COMMENT,
          ""
        );

        if (
          !beslQCComment ||
          !beslQCComment.value ||
          !beslQCComment.value.length > 0
        )
          return;
        return {
          ...beslQCComment,
          updatedAt: formatDate(beslQualityControlDoc.updatedAt),
        };
      }
    },
  })
  .filter("trustedFilter", trustedFilter);

export { template };
export default namespace;
