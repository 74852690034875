import angular from "angular";
import keys from "lodash/keys";
import pickBy from "lodash/pickBy";

import {
  compactCircleInfoForTechnischeControle,
  extendedCircleInfoForTechnischeControle,
  getComputedExpressionValue,
} from "@skryv/bundle/customizations/components/premiumCalculationHelpers";

import template from "./TechnischeControleSummary.html";
import "./TechnischeControleSummary.scss";

const namespace = "wrp/components/technischeControle/TechnischeControleSummary";

// names of the computed expressions used > this changes when you change the names/keys of the computed expressions
const CE_REQUESTED_CATEGORIES = "aangevraagde_categorieen";
const CE_REQUESTED_CATEGORIES_OK = "aangevraagde_categorieen_technisch_ok";
const CE_REQUESTED_CATEGORIES_NOK = "aangevraagde_categorieen_technisch_nok";
const CE_REQUESTED_CATEGORIES_ADMINISTRATIVELY_NOK = "aangevraagde_categorieen_administratief_nok";

angular.module(namespace, []).component("wrpTechnischeControleSummary", {
  template,
  bindings: {
    documentModel: "<",
  },
  controller: function ($timeout) {
    "ngInject";
    this.$onInit = () => {
      this.showExtendedSummary = false;

      // delay loading until the other elements on the page are done; this is not critical
      $timeout(() => {
        const allApprovedCategoriesKeys = getComputedExpressionValue(
          this.documentModel,
          CE_REQUESTED_CATEGORIES_OK
        );
        const allRejectedCategoriesKeys = getComputedExpressionValue(
          this.documentModel,
          CE_REQUESTED_CATEGORIES_NOK
        );
        const requestedCategoriesKeys = keys(
          pickBy(
            getComputedExpressionValue(
              this.documentModel,
              CE_REQUESTED_CATEGORIES
            ),
            (value) => value
          )
        );
        const administrativelyRejectedCategoriesKeys = keys(
          pickBy(
            getComputedExpressionValue(
              this.documentModel,
              CE_REQUESTED_CATEGORIES_ADMINISTRATIVELY_NOK
            ),
            (value) => value
          )
        );

        const requestedAndAdministrativelyOkCategories =  requestedCategoriesKeys.filter(category => !administrativelyRejectedCategoriesKeys.includes(category));

        this.compactCircleInfo = compactCircleInfoForTechnischeControle(
          requestedAndAdministrativelyOkCategories,
          allApprovedCategoriesKeys,
          allRejectedCategoriesKeys
        );
        this.extendedCircleInfo = extendedCircleInfoForTechnischeControle(
          requestedAndAdministrativelyOkCategories,
          allApprovedCategoriesKeys,
          allRejectedCategoriesKeys
        );
      });
    };
  },
});

export { template };
export default namespace;
