export function customFetchTasklistAction(
  listKey = "DEFAULT",
  requestPayload = {},
  pageNumber,
  pageSize
) {
  let params = Object.assign({ page: pageNumber - 1, size: pageSize });
  if (requestPayload.sorting) {
    params.sort =
      requestPayload.sorting[0].sortBy +
      "," +
      requestPayload.sorting[0].sortOrder;
    delete requestPayload.sorting;
  }
  return {
    type: "FETCH_TASKS",
    listKey: listKey,
    api: {
      params,
      url: "/api/extendedtasks/filtered",
      method: "POST",
      data: requestPayload,
    },
  };
}
