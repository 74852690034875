import angular from "angular";

import { taskTypes } from "@skryv/core-ng1/core/constants/taskTypes";
import { searchNames } from "@skryv/core-ng1/config/search";
import { parseFieldDefinition } from "@skryv/core-ng1/services/search/searchESFields";

import template from "@skryv/core-ng1/components/search/DossierSearch/DossierSearch.html";

import { customFetchTasklistAction } from "../../../store/actions";

const namespace = "@skryv/core-ng1/components/dashboard/MyTasksDashoard";

angular.module(namespace, []).component("wrpMyTasksDashboard", {
  template,
  controller: function ($ngRedux, userService, advancedSearch) {
    "ngInject";

    this.columnDefinitions = [
      {
        heading: "Comments",
        icon: "skr-icon-note-regular",
        field: "task.annotations",
        sort: false,
        type: "search/dossier/comments",
      },
      {
        heading: "Dossier",
        select: ["dossier.label", "dossier.id"],
        field: "dossier.label",
        sort: "dossier.label",
        state: () => "dossier",
        stateParams: (cell, searchHit) => {
          return {
            dossierId: parseFieldDefinition("dossier.id").extract(searchHit)[0],
          };
        },
      },
      {
        heading: "Task",
        field: "task.name",
        sort: "task.name",
        state: (cell, searchHit) => {
          if (searchHit.task.type === taskTypes.POP_UP) return "dossier";
          else return "task";
        },
        stateParams: (cell, searchHit) => {
          if (searchHit.task.type === taskTypes.POP_UP) {
            return {
              dossierId:
                parseFieldDefinition("dossier.id").extract(searchHit)[0],
            };
          }
          return {
            taskId: parseFieldDefinition("task.id").extract(searchHit)[0],
          };
        },
      },
      {
        heading: "Aanvraagdatum",
        field: "task.aanvraagdatum",
        key: "task.aanvraagdatum",
        sort: "task.aanvraagdatum",
        type: "search/date",
        format: "dd/MM/yyyy",
      },
      {
        heading: "Categorieën",
        field: "task.categorieen",
        key: "task.categorieen",
        type: "search/document/multichoice",
        sort: false,
        choices: [
          {
            name: "binnenrenovatie",
            label: "Binnenrenovatie",
          },
          {
            name: "muurrenovatie",
            label: "Buitenmuur",
          },
          {
            name: "dakrenovatie",
            label: "Dak",
          },
          {
            name: "technischeInstallaties",
            label: "Elektriciteit en sanitair",
          },
          {
            name: "gascondensatieketel",
            label: "Gascondensatieketel",
          },
          {
            name: "buitenschrijnwerkGlas",
            label: "Ramen en deuren",
          },
          {
            name: "vloerrenovatie",
            label: "Vloer",
          },
          {
            name: "warmtepomp",
            label: "Warmtepomp",
          },
          {
            name: "warmtepompboiler",
            label: "Warmtepompboiler",
          },
          {
            name: "zonneboiler",
            label: "Zonneboiler",
          },
        ],
      },
      {
        heading: "Uitvoeringsadres",
        field: "task.uitvoeringsadres",
        key: false,
        sort: false,
        type: "search/text",
      },
      {
        heading: "Aanvrager",
        field: "task.aanvrager",
        key: "task.aanvrager",
        sort: "task.aanvrager",
        type: "search/text",
      },
      {
        heading: "Type Aanvrager",
        field: "task.typeAanvrager",
        key: "task.typeAanvrager",
        type: "search/document/choice",
        sort: false,
        choices: [
          {
            name: "eigenaarBewoner",
            label: "Eigenaar-bewoner",
          },
          {
            name: "svkVerhuurder",
            label: "SVK-verhuurder",
          },
          {
            name: "onderneming",
            label: "Onderneming",
          },
          {
            name: "vme",
            label: "VME",
          },
          {
            name: "onbekend",
            label: "Andere",
          },
          {
            name: "huurder",
            label: "Huurder",
          },
          {
            name: "externeInvesteerder",
            label: "Enterne investeerder",
          },
        ],
      },
      {
        heading: "Type Gebouw",
        field: "task.typeGebouw",
        key: "task.typeGebouw",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "appartement",
            label: "Appartement",
          },
          {
            name: "eengezinswoning",
            label: "Eéngezinswoning",
          },
          {
            name: "appartementsgebouw",
            label: "Appartementsgebouw",
          },
          {
            name: "nietResidentieel",
            label: "Niet residentieel",
          },
        ],
      },
      {
        heading: "Doelgroep",
        field: "task.doelgroep",
        key: "task.doelgroep",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "doelgroep1",
            label: "Doelgroep 1",
          },
          {
            name: "doelgroep2",
            label: "Doelgroep 2",
          },
          {
            name: "doelgroep3",
            label: "Doelgroep 3",
          },
          {
            name: "dnk",
            label: "Kon geen beslissing nemen",
          },
        ],
      },
      {
        heading: "Type Dossier",
        field: "task.typeDossier",
        key: "task.typeDossier",
        sort: false,
        type: "search/document/choice",
        choices: [
          {
            name: "wvl",
            label: "Wonen-Vlaanderen",
          },
          {
            name: "fluvius",
            label: "Fluvius",
          },
          {
            name: "mix",
            label: "Gemixt",
          },
        ],
      },
      {
        heading: "In beroep",
        key: "task.dossierInBeroep",
        sort: false,
        type: "search/document/choice",
        field: "task.dossierInBeroep",
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
      {
        heading: "Is heropend",
        key: "task.dossierIsHeropend",
        sort: false,
        type: "search/document/choice",
        field: "task.dossierIsHeropend",
        choices: [
          {
            name: "ja",
            label: "Ja",
          },
          {
            name: "nee",
            label: "Nee",
          },
        ],
      },
    ];

    this.views = [
      {
        name: "Default view",
        searchTerm: "",
        columns: [
          "task.annotations",
          "dossier.label",
          "task.name",
          "task.aanvraagdatum",
          "task.categorieen",
          "task.uitvoeringsadres",
          // "task.aanvrager",
          // "task.typeAanvrager",
          // "task.doelgroep",
          // "task.typeDossier",
          "task.dossierInBeroep",
          "task.dossierIsHeropend",
        ],
        filters: [],
        sort: {
          criterion: "task.due",
          desc: false,
        },
      },
    ];

    this.searchDefinition = {
      key: "dashboard/tasks/v2",
      label: "tasks",
      datasourceType: "be/tasks",
      columnDefinitions: this.columnDefinitions,
      views: this.views,
      allowSearch: false,
      allowViewConfiguration: true,
      allowViewSelection: true,
      advancedSearch: advancedSearch.getAdvancedSearchConfigurationForKey(
        searchNames.DASHBOARD_MY_TASKS
      ),
      hiddenFilters: [
        {
          field: parseFieldDefinition("task.assignee"),
          value: userService.getCurrentUserSub(),
        },
      ],
      endpoint: (key) => (query, pageNumber, paginationSize) =>
        $ngRedux.dispatch(
          customFetchTasklistAction(key, query, pageNumber, paginationSize)
        ),
    };
  },
});

export { template };
export default namespace;
